import gsap from "gsap";

const main = async () => {
	// console.log("Loaded");
	const btn = document.getElementById("btn-signup");
	if (btn)
		btn.addEventListener("click", () => {
			const input = document.getElementById("signup-email");
			if (input && input.value)
				window.location = `https://app.getfriday.ai/auth/signup?email=${encodeURIComponent(
					input.value
				)}`;
		});

	let howLoaded = false;
	let featuresLoaded = false;
	let pricingLoaded = false;

	const observer = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					const section = entry.target;
					// console.log(section.id);
					switch (section.id) {
						case "how":
							animateHowItWorks();
							break;
						case "features":
							animateFeatures();
							break;
					}
				}
			});
		},
		{ threshold: 0.5 }
	);

	const sections = document.getElementsByTagName("section");
	for (let section of sections) {
		observer.observe(section);
	}

	const animateHowItWorks = () => {
		if (howLoaded) return;
		const duration = 0.8;
		const x = 0;
		gsap
			.timeline()
			.to("#design", { x, duration })
			.to("#identify", { x, duration })
			.to("#workflow", { x, duration })
			.to("#improve", { x, duration });
		howLoaded = true;
	};

	const animateFeatures = () => {
		if (featuresLoaded) return;
		gsap
			.timeline()
			.to(".ui", {
				x: 0,
				duration: 1,
			})
			.to(".feature", { x: 0, stagger: 0.8 }, "-=0.5")
			.to(".arrow", { opacity: 1 }, "+=0.5");
		featuresLoaded = true;
	};
};

window.addEventListener("load", main);
